// Retrieve Locations Request
export const RETRIEVE_LOCATION_REQUEST = 'RETRIEVE_LOCATION_REQUEST'
export function retrieveLocationRequest(uuid) {
  return { type: RETRIEVE_LOCATION_REQUEST, uuid }
}

// Retrieve Locations Response
export const RETRIEVE_LOCATION_RESPONSE = 'RETRIEVE_LOCATION_RESPONSE'
export function retrieveLocationResponse(data) {
  return { type: RETRIEVE_LOCATION_RESPONSE, data }
}

// Retrieve Locations Error
export const RETRIEVE_LOCATION_ERROR = 'RETRIEVE_LOCATION_ERROR'
export function retrieveLocationError(data) {
  return { type: RETRIEVE_LOCATION_ERROR, data }
}