// Package Dependencies
// Additional Dependencies
import {
  RETRIEVE_LOCATIONS_REQUEST,
  RETRIEVE_LOCATIONS_RESPONSE,
  RETRIEVE_LOCATIONS_ERROR
} from '../../action/Locations/Locations'

// Define Initial State
const INITIAL_STATE = {
  busy: false,
  processing: false,
  response: false,
  error: false,
  data: {
    data: []
  },
  location: {}
}

// Locations Reducer
function LocationsReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    // Retrieve Locations Request
    case RETRIEVE_LOCATIONS_REQUEST:
      return {
        ...state,
        busy: true,
        error: false,
        response: false
      }
    // Retrieve Locations Response
    case RETRIEVE_LOCATIONS_RESPONSE:
      return {
        ...state,
        busy: false,
        error: false,
        response: true,
        data: action.data
      }
    // Retrieve Locations Error
    case RETRIEVE_LOCATIONS_ERROR:
      return {
        ...state,
        busy: false,
        error: true,
        response: false,
        message: 'Retrieve Locations Error'
      }
    default:
      return state
  }
}

// Export
export default LocationsReducer