// Package Dependencies
import { combineReducers } from 'redux';
// Additional Dependencies
import AppointmentReducer from './Appointments/Transition';
import AppointmentsReducer from './Appointments/Appointments';
import LocationReducer from './Locations/Location';
import LocationsReducer from './Locations/Locations';
// Report Reducer
import readNetworkResourceReducer from '../data/redux/reducers/network/read';
// Combine Reducers
const RootReducer = combineReducers({
  appointmentReducer: AppointmentReducer,
  appointmentsReducer: AppointmentsReducer,
  locationReducer: LocationReducer,
  locationsReducer: LocationsReducer,
  readNetworkResourceReducer: readNetworkResourceReducer,
});
// Export
export default RootReducer;
