// Package Dependencies
import React from "react";
import { connect } from "react-redux";
import { DateTime } from "luxon";
import MainCard from "@alliancesafetycouncil/asc-card";
import Button from "@alliancesafetycouncil/asc-button";

// Additional Dependencies


class GroupedList extends React.Component {

  constructor(props) {
    super(props);
  }

  render() {
    const { data, active_index, handleTransitionAppointment, invite } = this.props

    const customers = data.reduce((res, item, index) => {
      if (index !== active_index) {
        res.push({
          id: index + 1,
          customer: `${item.contact.name.first} ${item.contact.name.last}`,
          time: DateTime.fromISO(item.appointment.date_time).setZone("UTC").toLocaleString(DateTime.TIME_SIMPLE),
          service: item.appointment.type.name,
          uuid: item.appointment.uuid
        })
      }
      return res
    }, [])

    const times = data.reduce((res, item, index) => {
      if (index !== active_index) {
        res.push(DateTime.fromISO(item.appointment.date_time).setZone("UTC").toLocaleString(DateTime.TIME_SIMPLE))
      }
      return res
    }, [])
    const distinct = Array.from(new Set(times))
    const timegroups = distinct.map(item => {
      return {
        time: item
      }
    })

    const findAppointmentIndex = (uuid) => data.findIndex(item => item.appointment.uuid === uuid)

    return (
      <div>
        {timegroups.map((group, index) => {
          return (
            <div key={`${group.time}-${index}`}>
              <div className="flex flex-row mt-8 mb-4 mx-2">
                <div className="text-xl text-gray-700 mr-4">{group.time}</div>
                <div className="border-b border-gray-400 flex-grow h-4"></div>
              </div>
              {customers
                .filter(element => group.time.includes(element.time))
                .map((element, index) => {
                  return (
                    <div key={`${group.time}-${element.service}-${index}`} className="-mb-2">
                      <MainCard type="main">
                        <div className="flex flex-col sm:flex-row">
                          <div className="flex flex-grow flex-col mb-4 sm:mb-0">
                            <div className="text-lg font-medium">{element.customer}</div>
                            <div className="text-gray-500">{element.service}</div>
                          </div>
                          <div>
                            {
                              !invite && <Button type="tertiary" size="md" onClick={(e) => {
                                e.preventDefault()
                                handleTransitionAppointment(findAppointmentIndex(element.uuid), "assigned")
                              }}>Invite to Window</Button>
                            }
                          </div>
                        </div>
                      </MainCard>
                    </div>
                  )
                })
              }
            </div>
          )
        })}
      </div>
    );
  }
}

export default GroupedList