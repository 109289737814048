import React from "react";
import MainCard from "@alliancesafetycouncil/asc-card";
import Button from "@alliancesafetycouncil/asc-button";
import Logo from "../../assets/img/fast-pass-logo.jpg";
import UserProfile from "../../components/UserProfile/UserProfile";
// import { locations } from "../../data/locations";
import { locations } from "../../data/locations-windows";

export const Locations = () => {

  const FSLogo = (e) => {
  	return (
        <img src={Logo} alt="" className="w-36"/>
      );
  }

  return (
    <div className="flex flex-col bg-blue-50 h-screen">
      {/* Main Header wrapper */}
      <div className="fixed bg-white w-full border-b border-gray-200">
        <div className="my-4 mx-4 flex flex-column">
					<div className="flex-grow">
	        	<FSLogo />
					</div>
					<div className="flex items-center">
						<UserProfile />
					</div>
        </div>
      </div>
      {/* Main Card wrapper */}
      <div className="mt-28 m-4 row content flex-auto">
        <MainCard type="main">
          <div className="flex-column text-center">
            <div className="my-8 text-xl font-bold">Select your location.</div>
            <div className="flex flex-col gap-6 justify-center items-center">

              {locations.map((location) => (
                <div className="flex flex-row w-full items-center justify-center" key={location.id}>
                  <Button type="primary" size="md" href={`/windows/${location.id}`}><span className="w-72">{location.name}</span></Button>
                </div>
              ))}
            </div>
          </div>
        </MainCard>
      </div>
    </div>
  );
};

export default Locations;
