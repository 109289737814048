// Redux Actions
// Docs: https://redux.js.org/basics/actions
//* Asynchronous Network Resource Read Actions

//! Import Dependencies
// Redux Actions: Types
import { READ_NETWORK_RESOURCE_IDLE, READ_NETWORK_RESOURCE_PENDING, READ_NETWORK_RESOURCE_FAILED, READ_NETWORK_RESOURCE_SUCCEEDED } from './types';

//^ Export Dependencies
/// Network Actions: Read Resources
export const readNetworkResourceIdle = (data) => ({ type: READ_NETWORK_RESOURCE_IDLE, data: data });
export const readNetworkResourcePending = (data) => ({ type: READ_NETWORK_RESOURCE_PENDING, data: data });
export const readNetworkResourceFailed = (data) => ({ type: READ_NETWORK_RESOURCE_FAILED, data: data });
export const readNetworkResourceSucceeded = (data) => ({ type: READ_NETWORK_RESOURCE_SUCCEEDED, data: data });
