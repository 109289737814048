// Redux Saga
// Docs: https://redux-saga.js.org/
//* Asynchronous Network Resource Read Saga

//! Import Dependencies
// Redux Saga: Effects
import { call, put, takeLatest } from 'redux-saga/effects';
// Axios: Promise Based HTTP Client for the Browser and Node.js
import Axios from '../../../../utility/Axios';
// Redux Actions: Types
import { READ_NETWORK_RESOURCE_PENDING } from '../../actions/network/types';
// Redux Actions
import { readNetworkResourceSucceeded, readNetworkResourceFailed } from '../../actions/network/read';

// Function to Get Location UUID
function getLocationUUID() {
  const afterHash = window.location.href.split('#')[1];
  const params = new URLSearchParams(afterHash.split('?')[1]);
  const location_uuid = params.get('location_uuid');

  return location_uuid;
}

//& Redux Saga: Network Resource Read Saga
function* handleReadNetworkResource(action) {
  try {
    //~ Define Network Resource Read Request Tasks

    //? Task: FastPass Admin 2.0: Appointment History Report
    const resource = '/appointment/query';

    const params = {
      location_uuid: getLocationUUID(),
      date_from: '',
      date_to: '',
      contact_name: '',
      sort_column: 'date_time',
      sort_direction: 'desc',
      limit: 20,
      offset: action.data,
    };

    // Filter and Construct Query String from URL Parameters
    const querystring = Object.keys(params)
      .filter((key) => params[key] !== null && params[key] !== undefined && params[key] !== '')
      .map((key) => `${key}=${params[key]}`)
      .join('&');

    //* Perform Axios HTTP Client REST API Invocation: FastPass API 2.0
    const response = yield call(Axios.get_request, `${resource}?${querystring}`);

    /* Handle API and Network Response
     * Immediately Dispatch the REST API Invocation Results + Dataset Payload to the readNetworkResourceReducer()
     * Redux Reducer via the readNetworkResourceSucceeded() Redux Action to (i) Handle the API Inovcation Result,
     * (ii) Update the Redux Store, (iii) Fire the React-Redux forceUpdate() and shouldComponentUpdate()
     * Lifecycle Events to (iv) Update the Virtual DOM and (v) Push the Updated State to the Web Browser to
     * (vi) Render and Compute the Content using the Browser's DOM State.
     */

    //? Redux Action: Read Network Resource Succeeded
    yield put(readNetworkResourceSucceeded(response.data.data));
  } catch (error) {
    //! Handle API and Network Errors

    //^ Dispatch the Results to the readNetworkResourceReducer() Redux Reducer via the readNetworkResourceFailed() Redux Action:

    //? Redux Action: Read Network Resource Failed
    yield put(readNetworkResourceFailed(error));
  }
}

//^ Export Dependencies
// Redux Saga: Network Resource Read Saga
export const readNetworkResourceSaga = [takeLatest(READ_NETWORK_RESOURCE_PENDING, handleReadNetworkResource)];
