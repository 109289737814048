import React from 'react';

import { DateTime } from 'luxon';

import Button from '@alliancesafetycouncil/asc-button';
import MainCard from '@alliancesafetycouncil/asc-card';

// Function to Get Location UUID
function getLocationUUID() {
  const afterHash = window.location.href.split("#")[1];
  const params = new URLSearchParams(afterHash.split("?")[1]);
  const location_uuid = params.get("location_uuid");

  return location_uuid;
}

export const BeginAppointment = ({ active_appointment, active_index, handleTransitionAppointment }) => {
  const data = active_appointment

  return (
    <div className="mx-2 mb-1">
      <MainCard type="main">
        <div className="flex flex-col sm:flex-row">
          <div className="mb-4 mr-0 sm:flex-grow sm:mr-4 sm:mb-0">
            <div>
              <i className="mb-4 mr-2 text-lg text-blue-500 fal fa-clock"></i><span className="text-lg text-blue-500">Waiting...</span>
            </div>
            <div>
              <span className="text-gray-700">{DateTime.fromISO(data.appointment.date_time).setZone("UTC").toLocaleString(DateTime.TIME_SIMPLE)}</span>
            </div>
            <div>
              <span className="text-2xl font-medium">{`${data.contact.name.first} ${data.contact.name.last}`}</span>
            </div>
            <div>
              <span className="text-gray-700">{data.appointment.type.name}</span>
            </div>
          </div>
          <div className="flex items-end">
            <div className="mr-4">
              <Button type="tertiary" size="md" onClick={() => handleTransitionAppointment(active_index, "no_show")}>No Show</Button>
            </div>
            <div className="mr-4">
              <Button type="tertiary" size="md" onClick={() => handleTransitionAppointment(active_index, "queued", getLocationUUID())}>Re-Assign</Button>
            </div>
            <div>
              <Button type="save" size="md" onClick={() => handleTransitionAppointment(active_index, "in_progress")}>Begin Appointment</Button>
            </div>
          </div>
        </div>
      </MainCard>
    </div>
  );
};

export default BeginAppointment;
