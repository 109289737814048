import React from "react";
import { DateTime } from "luxon";
import MainCard from "@alliancesafetycouncil/asc-card";
import Button from "@alliancesafetycouncil/asc-button";

export const EndAppointment = ({ active_appointment, active_index, handleTransitionAppointment }) => {
  const data = active_appointment

  return (
    <div className="mx-2 mb-1">
      <MainCard type="main">
        <div className="flex flex-col sm:flex-row">
          <div className="sm:flex-grow mr-0 sm:mr-4 mb-4 sm:mb-0">
            <div>
              <i className="fal fa-clock text-green-500 mr-2 mb-4 text-lg"></i><span className="text-green-500 text-lg">Appointment In Progress...</span>
            </div>
            <div>
              <span className="text-gray-700">{DateTime.fromISO(data.appointment.date_time).setZone("UTC").toLocaleString(DateTime.TIME_SIMPLE)}</span>
            </div>
            <div>
              <span className="text-2xl font-medium">{`${data.contact.name.first} ${data.contact.name.last}`}</span>
            </div>
            <div>
              <span className="text-gray-700">{data.appointment.type.name}</span>
            </div>
          </div>
          <div className="flex items-end">
            <div className="mr-4">
              <Button type="tertiary" size="md" onClick={() => handleTransitionAppointment(active_index, "assigned")}>Go Back</Button>
            </div>
            <div>
              <Button type="delete" size="md" onClick={() => handleTransitionAppointment(active_index, "complete")}>End Appointment</Button>
            </div>
          </div>
        </div>
      </MainCard>
    </div>
  );
};

export default EndAppointment;
