import React from 'react';

import {
  HashRouter as Router,
  Route,
  Switch,
} from 'react-router-dom';

import Appointments from './views/Appointments';
import Locations from './views/Locations';
import Report from './views/Report';
import Settings from './views/Settings';
import WindowSettings from './views/Settings/windows';
import SignIn from './views/SignIn';
import Windows from './views/Windows';

function App() {
  return (
    <div className="flex flex-row">
      <Router>
        <div className="relative z-10 flex nav"></div>
        <div className="relative z-0 w-full">
          <Switch>
            <Route exact path="/" name="SignIn" component={SignIn} />
            <Route exact path="/appointments/:uuid" name="Appointments" component={Appointments} />
            <Route exact path="/locations" name="Locations" component={Locations} />
            <Route exact path="/report" name="Report" component={Report} />
            <Route exact path="/settings" name="Settings" component={Settings} />
            <Route exact path="/settings/windows/:id" name="WindowSettings" component={WindowSettings} />
            <Route exact path="/windows" name="Windows" component={Windows} />
          </Switch>
        </div>
      </Router>
    </div>
  );
}

export default App;
