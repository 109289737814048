// Retrieve Appointments Request
export const RETRIEVE_APPOINTMENTS_REQUEST = 'RETRIEVE_APPOINTMENTS_REQUEST'
export function retrieveAppointmentsRequest(uuid) {
  return { type: RETRIEVE_APPOINTMENTS_REQUEST, uuid }
}

// Retrieve Appointments Response
export const RETRIEVE_APPOINTMENTS_RESPONSE = 'RETRIEVE_APPOINTMENTS_RESPONSE'
export function retrieveAppointmentsResponse(data, active_index, active_appointment) {
  return { type: RETRIEVE_APPOINTMENTS_RESPONSE, data, active_index, active_appointment }
}

// Retrieve Appointments Error
export const RETRIEVE_APPOINTMENTS_ERROR = 'RETRIEVE_APPOINTMENTS_ERROR'
export function retrieveAppointmentsError(data) {
  return { type: RETRIEVE_APPOINTMENTS_ERROR, data }
}

// Set Active Appointment
export const SET_ACTIVE_APPOINTMENT = 'SET_ACTIVE_APPOINTMENT'
export function setActiveAppointment(active_index, active_appointment) {
  return { type: SET_ACTIVE_APPOINTMENT, active_index, active_appointment }
}
