import React from 'react';

import { connect } from 'react-redux';

import Button from '@alliancesafetycouncil/asc-button';
import MainCard from '@alliancesafetycouncil/asc-card';

// Additional Dependencies
import { retrieveLocationsRequest } from '../../action/Locations/Locations';
import Logo from '../../assets/img/fast-pass-logo.jpg';
import UserProfile from '../../components/UserProfile/UserProfile';

// Function to Get Location UUID
function getLocationUUID() {
  const afterHash = window.location.href.split("#")[1];
  const params = new URLSearchParams(afterHash.split("?")[1]);
  const location_uuid = params.get("location_uuid");

  return location_uuid
}

const FSLogo = () => {
  return (
    <img src={Logo} alt="" className="w-36" />
  );
}

class Windows extends React.Component {
  constructor(props) {
    super(props)
  }

  componentDidMount() {
    this.props.FetchLocations()
  }

  render() {
    return (
      <div className="flex flex-col h-screen bg-blue-50">
        {/* Main Header wrapper */}
        <div className="fixed w-full bg-white border-b border-gray-200">
          <div className="flex mx-4 my-4 flex-column">
            <div className="flex-grow">
              <FSLogo />
            </div>
            <div className="flex items-center">
              <UserProfile />
            </div>
          </div>
        </div>
        {/* Main Card wrapper */}
        <div className="flex-auto m-4 mt-28 row content">
          <MainCard type="main">
            <div className="text-center flex-column">
              <div className="flex grid justify-center grid-cols-1 gap-6">
                {
                  this.props.data.data.map((item, index) => {
                    return (
                      <div key={`window-${item.location.name}-${index}`} className="justify-center flex-auto">
                        <Button type="primary" size="md"><span className="mx-16" onClick={() => this.props.history.push(`/appointments/${item.location.uuid}?location_uuid=${getLocationUUID()}`)}>{item.location.name}</span></Button>
                      </div>
                    )
                  })
                }
              </div>
            </div>
          </MainCard>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    data: state.locationsReducer.data
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    FetchLocations: () => dispatch(retrieveLocationsRequest())
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Windows)
