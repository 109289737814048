// Package Dependencies
// Additional Dependencies
import {
  RETRIEVE_LOCATION_REQUEST,
  RETRIEVE_LOCATION_RESPONSE,
  RETRIEVE_LOCATION_ERROR
} from '../../action/Locations/Location'

// Define Initial State
const INITIAL_STATE = {
  busy: false,
  processing: false,
  response: false,
  error: false,
  location: {}
}

// Locations Reducer
function LocationReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    // Retrieve Location Request
    case RETRIEVE_LOCATION_REQUEST:
      return {
        ...state,
        busy: true,
        error: false,
        response: false
      }
    // Retrieve Location Response
    case RETRIEVE_LOCATION_RESPONSE:
      return {
        ...state,
        busy: false,
        error: false,
        response: true,
        location: action.data.data.location
      }
    // Retrieve Location Error
    case RETRIEVE_LOCATION_ERROR:
      return {
        ...state,
        busy: false,
        error: true,
        response: false,
        message: 'Retrieve Location Error'
      }
    default:
      return state
  }
}

// Export
export default LocationReducer