// Package Dependencies
import { call, put, takeLatest } from 'redux-saga/effects'
// Additional Dependencies
import Axios from '../../utility/Axios'
// Action Dependencies
import {
  RETRIEVE_LOCATION_REQUEST,
  retrieveLocationResponse,
  retrieveLocationError
} from '../../action/Locations/Location'

// Function Handle Location
function* handleLocation(action) {
  try {
    // Retrieve Locations Request
    const response = yield call(Axios.get_request, `/location/${action.uuid}`)
    // Retrieve Locations Response
    yield put(retrieveLocationResponse(response.data))
  } catch (error) {
    // Retrieve Locations Error
    yield put(retrieveLocationError(error))
  }
}

// Export
export const LocationSaga = [
  takeLatest(RETRIEVE_LOCATION_REQUEST, handleLocation)
]