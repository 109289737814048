import React from 'react';

import { connect } from 'react-redux';

import {
  retrieveAppointmentsRequest,
} from '../../action/Appointments/Appointments';
import {
  transitionAppointmentRequest,
  undoTransitionAppointmentRequest,
} from '../../action/Appointments/Transition';
// Additional Dependencies
import { retrieveLocationRequest } from '../../action/Locations/Location';
import Logo from '../../assets/img/fast-pass-logo.jpg';
import BeginAppointment from '../../components/Appointments/BeginAppointment';
import EndAppointment from '../../components/Appointments/EndAppointment';
import Invite from '../../components/Appointments/Invite';
import GroupedList from '../../components/GroupedList/GroupedList';
import UndoAction from '../../components/UndoAction/UndoAction';
import UserProfile from '../../components/UserProfile/UserProfile';

const FSLogo = (e) => {
  return <img src={Logo} alt="" className="w-36" />;
};

const Undo = ({ handleUndoTransition }) => {
  return (
    <div className="fixed bottom-4 left-4">
      <UndoAction handleUndoTransition={handleUndoTransition} />
    </div>
  );
};

const AppointmentState = ({
  active_appointment,
  active_index,
  state = "queued",
  handleTransitionAppointment,
}) => {
  switch (state) {
    case "queued":
      return (
        <Invite
          active_appointment={active_appointment}
          active_index={active_index}
          handleTransitionAppointment={handleTransitionAppointment}
        />
      );
    case "assigned":
      return (
        <BeginAppointment
          active_appointment={active_appointment}
          active_index={active_index}
          handleTransitionAppointment={handleTransitionAppointment}
        />
      );
    case "in_progress":
      return (
        <EndAppointment
          active_appointment={active_appointment}
          active_index={active_index}
          handleTransitionAppointment={handleTransitionAppointment}
        />
      );
    default:
      return null;
  }
};

class Appointments extends React.Component {
  timer;

  constructor(props) {
    super(props);
  }

  setTimer = () => {
    const uuid = this.props.match.params.uuid;

    this.timer = setInterval(() => this.props.FetchAppointments(uuid), 2000);
  };

  handleUndoTransition = () => {
    this.props.UndoTransitionAppointment();
  };

  handleTransitionAppointment = (
    active_index,
    state,
    location_uuid = this.props.match.params.uuid
  ) => {
    this.props.TransitionAppointment(active_index, state, location_uuid);
  };

  componentWillUnmount() {
    clearInterval(this.timer);
  }

  componentDidMount() {
    const uuid = this.props.match.params.uuid;

    this.props.FetchLocation(uuid);
    this.props.FetchAppointments(uuid);
    this.setTimer();
  }

  render() {
    const { location, appointments, undo, active_appointment, active_index, invite } = this.props;

    return (
      <div className="flex flex-col h-screen bg-blue-50">
        {undo.show && <Undo handleUndoTransition={() => this.handleUndoTransition()} />}
        {/* Main Header wrapper */}
        <div className="fixed w-full bg-white border-b border-gray-200">
          {/* Window */}
          <div className="absolute flex justify-center invisible w-full sm:visible ">
            <div className="flex items-center justify-center w-48 h-10 text-white bg-blue-500 rounded-b-lg">
              <div>{location.name}</div>
            </div>
          </div>
          <div className="flex mx-4 my-4 flex-column">
            <div className="flex-grow">
              <FSLogo />
            </div>

            <div className="flex items-center visible mr-3 sm:invisible">
              <div className="flex items-center justify-center w-8 h-8 text-xs font-medium text-white bg-blue-500 rounded-full">
                <div>#1</div>
              </div>
            </div>

            <div className="flex items-center">
              <UserProfile />
            </div>
          </div>
        </div>
        <div className="mt-28"></div>
        {/* Panel States */}
        {active_appointment?.appointment !== undefined && (
          <AppointmentState
            active_appointment={active_appointment}
            active_index={active_index}
            state={active_appointment?.appointment?.state.alias}
            handleTransitionAppointment={this.handleTransitionAppointment}
          />
        )}
        <div className="mx-2 -mt-4">
          <GroupedList
            data={appointments}
            active_index={active_index}
            invite={invite}
            handleTransitionAppointment={this.handleTransitionAppointment}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    location: state.locationReducer.location,
    appointments: state.appointmentsReducer.data,
    undo: state.appointmentReducer.undo,
    active_appointment: state.appointmentsReducer.active_appointment,
    active_index: state.appointmentsReducer.active_index,
    invite: state.appointmentsReducer.invite,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    FetchLocation: (uuid) => dispatch(retrieveLocationRequest(uuid)),
    FetchAppointments: (uuid) => dispatch(retrieveAppointmentsRequest(uuid)),
    TransitionAppointment: (active_index, state, location_uuid) =>
      dispatch(transitionAppointmentRequest({ active_index, state, location_uuid })),
    UndoTransitionAppointment: () => dispatch(undoTransitionAppointmentRequest()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Appointments);
