// Package Dependencies
import { all } from 'redux-saga/effects';
import { AppointmentsSaga } from './Appointments/Appointments';
import { TransitionAppointmentSaga } from './Appointments/Transition';
import { LocationSaga } from './Locations/Location';
import { LocationsSaga } from './Locations/Locations';
// Network Resource Saga
import { readNetworkResourceSaga } from '../data/redux/sagas/network/read';
// Export
export default function* RootSaga() {
  yield all([
    ...AppointmentsSaga,
    ...TransitionAppointmentSaga,
    ...LocationSaga,
    ...LocationsSaga,
    ...readNetworkResourceSaga,
  ]);
}
