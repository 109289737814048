// Redux Actions: Types
// Docs: https://redux.js.org/basics/actions
//* Asynchronous Network Action Types

//! Import Dependencies

//^ Export Dependencies
/// Network Action Types: Create Resources
export const CREATE_NETWORK_RESOURCE_IDLE = 'CREATE_NETWORK_RESOURCE_IDLE';
export const CREATE_NETWORK_RESOURCE_PENDING = 'CREATE_NETWORK_RESOURCE_PENDING';
export const CREATE_NETWORK_RESOURCE_FAILED = 'CREATE_NETWORK_RESOURCE_FAILED';
export const CREATE_NETWORK_RESOURCE_SUCCEEDED = 'CREATE_NETWORK_RESOURCE_SUCCEEDED';
/// Network Action Types: Read Resources
export const READ_NETWORK_RESOURCE_IDLE = 'READ_NETWORK_RESOURCE_IDLE';
export const READ_NETWORK_RESOURCE_PENDING = 'READ_NETWORK_RESOURCE_PENDING';
export const READ_NETWORK_RESOURCE_FAILED = 'READ_NETWORK_RESOURCE_FAILED';
export const READ_NETWORK_RESOURCE_SUCCEEDED = 'READ_NETWORK_RESOURCE_SUCCEEDED';
/// Network Action Types: Update Resources
export const UPDATE_NETWORK_RESOURCE_IDLE = 'UPDATE_NETWORK_RESOURCE_IDLE';
export const UPDATE_NETWORK_RESOURCE_PENDING = 'UPDATE_NETWORK_RESOURCE_PENDING';
export const UPDATE_NETWORK_RESOURCE_FAILED = 'UPDATE_NETWORK_RESOURCE_FAILED';
export const UPDATE_NETWORK_RESOURCE_SUCCEEDED = 'UPDATE_NETWORK_RESOURCE_SUCCEEDED';
/// Network Action Types: Delete Resources
export const DELETE_NETWORK_RESOURCE_IDLE = 'DELETE_NETWORK_RESOURCE_IDLE';
export const DELETE_NETWORK_RESOURCE_PENDING = 'DELETE_NETWORK_RESOURCE_PENDING';
export const DELETE_NETWORK_RESOURCE_FAILED = 'DELETE_NETWORK_RESOURCE_FAILED';
export const DELETE_NETWORK_RESOURCE_SUCCEEDED = 'DELETE_NETWORK_RESOURCE_SUCCEEDED';
