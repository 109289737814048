import React from 'react';

import Button from '@alliancesafetycouncil/asc-button';

import Logo from '../../assets/img/fast-pass-logo.jpg';

export const SignIn = (props) => {
  
  // Function to Get Location UUID
  function getLocationUUID() {
    const afterHash = window.location.href.split("#")[1];
    const params = new URLSearchParams(afterHash.split("?")[1]);
    const location_uuid = params.get("location_uuid");

    return location_uuid;
  }

  const FSLogo = (e) => {
    return <img src={Logo} alt="" className="w-36" />;
  };

  return (
    <div className="flex flex-col h-screen">
      {/* Main Header wrapper */}
      <div className="fixed w-full bg-white">
        <div className="flex mx-4 my-4 flex-column">
          <div className="flex-grow">
            <FSLogo />
          </div>
        </div>
      </div>
      {/* Main Card wrapper */}
      <div className="flex-auto m-4 mt-28 row content">
        <div className="w-full m-auto flex-column sm:w-1/2">
          <div className="my-8 text-2xl font-medium text-center">Sign In</div>
          <div className="flex grid grid-cols-1 gap-6">
            <div>
              <label className="block mb-2 font-medium text-gray-900" htmlFor="username">
                Email Address
              </label>
              <input
                className="w-full h-10 px-3 py-2 leading-tight text-gray-700 border-0 rounded shadow appearance-none focus:outline-none focus:shadow-outline"
                id="username"
                type="text"
                placeholder="Username"
              />
            </div>
            <div>
              <label className="block mb-2 font-medium text-gray-900" htmlFor="username">
                Password
              </label>
              <input
                className="w-full h-10 px-3 py-2 leading-tight text-gray-700 border-0 rounded shadow appearance-none focus:outline-none focus:shadow-outline"
                id="psddeotf"
                type="password"
                placeholder="Password"
              />
            </div>
            <div className="justify-center flex-auto">
              <Button type="primary" size="md" onClick={() => props.history.push(`/windows?location_uuid=${getLocationUUID()}`)}>
                <span className="mx-16">Sign In</span>
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignIn;
