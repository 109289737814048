// Redux Reducer
// Docs: https://redux.js.org/basics/reducers
//* Asynchronous Network Resource Read Reducer

//! Import Dependencies
// Redux Actions: Types
import {
  READ_NETWORK_RESOURCE_IDLE,
  READ_NETWORK_RESOURCE_PENDING,
  READ_NETWORK_RESOURCE_FAILED,
  READ_NETWORK_RESOURCE_SUCCEEDED,
} from '../../actions/network/types';

//? Redux Reducer: Initial State
const INITIAL_STATE = {
  // Reducer State
  init: null,
  // Network State
  link: null,
  mode: null,
  // Session State
  auth: null,
  role: null,
  // Activity State
  idle: null,
  wait: null,
  busy: null,
  load: null,
  proc: null,
  fail: null,
  // Background State
  task: [],
  meta: null,
  // Resource State
  data: [],
  info: null,
};

//& Redux Reducer: Network Resource Read Reducer
function readNetworkResourceReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    // Read Network Resource Idle
    case READ_NETWORK_RESOURCE_IDLE:
      return {
        ...state,
        // Reducer State
        init: true,
        // Network State
        link: true,
        mode: 'normal',
        // Session State
        // auth: null,
        // role: null,
        // Activity State
        idle: true,
        wait: false,
        busy: false,
        load: false,
        proc: false,
        fail: false,
        // Background State
        // task: [],
        // meta: null,
        // Resource State
        // data: [],
        info: 'Ready for Network Requests',
      };
    // Read Network Resource Pending
    case READ_NETWORK_RESOURCE_PENDING:
      return {
        ...state,
        // Reducer State
        init: true,
        // Network State
        link: true,
        mode: 'normal',
        // Session State
        // auth: null,
        // role: null,
        // Activity State
        idle: false,
        wait: true,
        busy: true,
        load: true,
        proc: true,
        fail: false,
        // Background State
        // task: [],
        // meta: null,
        // Resource State
        // data: [],
        info: 'Waiting for Network Response',
      };
    // Read Network Resource Succeeded
    case READ_NETWORK_RESOURCE_SUCCEEDED:
      return {
        ...state,
        // Reducer State
        init: true,
        // Network State
        link: true,
        mode: 'normal',
        // Session State
        // auth: null,
        // role: null,
        // Activity State
        idle: false,
        wait: false,
        busy: false, // READ_NETWORK_RESOURCE_IDLE { true -> false }
        load: false,
        proc: true,
        fail: false,
        // Background State
        // task: [],
        // meta: null,
        // Resource State
        data: state.data.concat(action.data),
        // data: action.data,
        info: 'Network Success Response Received',
      };
    // Read Network Resource Failed
    case READ_NETWORK_RESOURCE_FAILED:
      return {
        ...state,
        // Reducer State
        init: false,
        // Network State
        link: false,
        mode: 'unknown',
        // Session State
        // auth: null,
        // role: null,
        // Activity State
        idle: false,
        wait: true,
        busy: true,
        load: false,
        proc: false,
        fail: true,
        // Background State
        // task: [],
        // meta: null,
        // Resource State
        // data: [],
        info: action.error || 'Unknown Error or Network Timeout Response Received',
      };
    // Default
    default:
      return state;
  }
}

//^ Export Dependencies
// Redux Reducer: Network Resource Read Reducer
export default readNetworkResourceReducer;
