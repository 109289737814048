// FastPass Admin
// Redux Infinite Scrolling Component for Report Data
import React, { useEffect, useRef } from 'react';

import { connect, useDispatch, useSelector } from 'react-redux';
import { DateTime } from 'luxon';
import { parsePhoneNumber } from 'libphonenumber-js';

import { readNetworkResourcePending } from '../../actions/network/read';

const InfiniteScroll = (props) => {
  const dispatch = useDispatch();
  const state = useSelector((state) => state.readNetworkResourceReducer);
  const offset = useRef(0);
  const scroll = useRef(0);

  // Define Request Payload
  const payload = {
    contact_name: props?.contact_name ?? '',
    date_from: props?.date_from ?? '',
    date_to: props?.date_to ?? '',
    offset: offset.current,
  };

  // Initialize Redux State
  useEffect(() => {
    if (!state.init) {
      dispatch(readNetworkResourcePending(offset.current));
    }
  }, [dispatch, state]);

  // Infinite Scrolling with Redux
  useEffect(() => {
    const ready = state.init && !state.busy && !state.load ? true : false;

    const handleScroll = () => {
      const scrollPosition = window.innerHeight + document.documentElement.scrollTop;
      const offsetHeight = document.documentElement.offsetHeight;

      if (scrollPosition > scroll.current) {
        scroll.current = scrollPosition;

        if (scrollPosition < offsetHeight * 0.9) return;

        if (ready) {
          offset.current += 10;
          dispatch(readNetworkResourcePending(offset.current));
        }
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [dispatch, state]);

  return (
    <div>
      {state.data.map((item, index) => (
        <div
          key={index}
          className={`grid grid-cols-6 gap-3 px-3 h-12 items-center ${index % 2 === 1 ? 'bg-gray-100' : 'bg-white'}`}
        >
          <div>
            {item.contact.name.first} {item.contact.name.last}
          </div>
          <div>{parsePhoneNumber(item.contact.mobile, 'US').formatNational()}</div>
          <div>
            {DateTime.fromISO(item.appointment.date_time.scheduled).setZone('UTC').toLocaleString(DateTime.TIME_SIMPLE)}
          </div>
          <div>
            {DateTime.fromISO(item.appointment.date_time.scheduled).setZone('UTC').toLocaleString(DateTime.DATE_SIMPLE)}
          </div>
          {/* <div>{item.location.name}</div> */}
          <div>Agent 1</div>
        </div>
      ))}
    </div>
  );
};

const mapStateToProps = (state) => {
  return { data: state.readNetworkResourceReducer.data };
};

const mapDispatchToProps = (dispatch) => {
  return { readNetworkResourceFetch: () => dispatch(readNetworkResourcePending()) };
};

export default connect(mapStateToProps, mapDispatchToProps)(InfiniteScroll);
