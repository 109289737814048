// Transition Appointment Request
export const TRANSITION_APPOINTMENT_REQUEST = 'TRANSITION_APPOINTMENT_REQUEST'
export function transitionAppointmentRequest(data) {
  return { type: TRANSITION_APPOINTMENT_REQUEST, data }
}

// Transition Appointment Response
export const TRANSITION_APPOINTMENT_RESPONSE = 'TRANSITION_APPOINTMENT_RESPONSE'
export function transitionAppointmentResponse(undo) {
  return { 
    type: TRANSITION_APPOINTMENT_RESPONSE, 
    payload: {
      undo,
    } 
  }
}

// Transition Appointment Error
export const TRANSITION_APPOINTMENT_ERROR = 'TRANSITION_APPOINTMENT_ERROR'
export function transitionAppointmentError(data) {
  return { type: TRANSITION_APPOINTMENT_ERROR, data }
}

// Undo Transition Appointment
export const UNDO_TRANSITION_APPOINTMENT_REQUEST = 'UNDO_TRANSITION_APPOINTMENT_REQUEST'
export function undoTransitionAppointmentRequest(data) {
  return { type: UNDO_TRANSITION_APPOINTMENT_REQUEST, data }
}