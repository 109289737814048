// Retrieve Locations Request
export const RETRIEVE_LOCATIONS_REQUEST = 'RETRIEVE_LOCATIONS_REQUEST'
export function retrieveLocationsRequest(data) {
  return { type: RETRIEVE_LOCATIONS_REQUEST, data }
}

// Retrieve Locations Response
export const RETRIEVE_LOCATIONS_RESPONSE = 'RETRIEVE_LOCATIONS_RESPONSE'
export function retrieveLocationsResponse(data) {
  return { type: RETRIEVE_LOCATIONS_RESPONSE, data }
}

// Retrieve Locations Error
export const RETRIEVE_LOCATIONS_ERROR = 'RETRIEVE_LOCATIONS_ERROR'
export function retrieveLocationsError(data) {
  return { type: RETRIEVE_LOCATIONS_ERROR, data }
}