// Write an api for locations with and id, name, and windows. The windows will have a name, status, and occupied.

export const locations = [
    {
        id: 1,
        name: "Lagos",
        windows: [
            {
                id: 1,
                name: "Window 1",
                status: "Active",
                occupied: false,
            },
            {
                id: 2,
                name: "Window 2",
                status: "Active",
                occupied: false,
            },
            {
                id: 3,
                name: "Window 3",
                status: "Active",
                occupied: true,
            },
            {
                id: 4,
                name: "Window 4",
                status: "Inactive",
                occupied: false,
            },
            {
                id: 5,
                name: "Window 5",
                status: "Active",
                occupied: true,
            },
        ],
    },
    {
        id: 2,
        name: "Abuja",
        windows: [
            {
                id: 1,
                name: "Window 1",
                status: "Active",
                occupied: false,
            },
            {
                id: 2,
                name: "Window 2",
                status: "Active",
                occupied: false,
            },
            {
                id: 3,
                name: "Window 3",
                status: "Active",
                occupied: true,
            },
        ],
    },
    {
        id: 3,
        name: "Port Harcourt",
        windows: [
            {
                id: 1,
                name: "Window 1",
                status: "Active",
                occupied: false,
            },
            {
                id: 2,
                name: "Window 2",
                status: "Active",
                occupied: false,
            },
            {
                id: 3,
                name: "Window 3",
                status: "Active",
                occupied: true,
            }
        ]
    },
    {
        id: 3,
        name: "Ibadan Lagoon",
        windows: [
            {
                id: 1,
                name: "Window 1",
                status: "Active",
                occupied: false,
            },
            {
                id: 2,
                name: "Window 2",
                status: "Active",
                occupied: false,
            },
            {
                id: 3,
                name: "Window 3",
                status: "Active",
                occupied: true,
            }
        ]
    }
]
