// Package Dependencies
import {
  call,
  put,
  select,
  takeLatest,
} from 'redux-saga/effects';

import { setActiveAppointment } from '../../action/Appointments/Appointments';
// Action Dependencies
import {
  TRANSITION_APPOINTMENT_REQUEST,
  transitionAppointmentError,
  transitionAppointmentResponse,
  UNDO_TRANSITION_APPOINTMENT_REQUEST,
} from '../../action/Appointments/Transition';
// Additional Dependencies
import Axios from '../../utility/Axios';

// Function Handle Transition Appointment
function* handleTransitionAppointment(action) {
  try {
    const active_appointment = yield select((state) => state.appointmentsReducer.data[action.data.active_index])
    const undo = {
      show: action.data.state === "complete",
      state: active_appointment.appointment.state.alias,
      appointment_uuid: active_appointment.appointment.uuid,
      location_uuid: active_appointment.location.uuid
    }
    // Transition Appointment Request
    const response = yield call(Axios.patch_request, `/appointment/${active_appointment.appointment.uuid}/transition`, {
      "version": "1.0.0",
      "data": {
        "state": action.data.state,
        "location": {
          "uuid": action.data.location_uuid
        }
      }
    })
    // Transition Appointment Response
    yield put(transitionAppointmentResponse(undo))
    // Set Active Appointment
    yield put(setActiveAppointment(action.data.active_index, active_appointment))
  } catch (error) {
    // Transition Appointment Error
    yield put(transitionAppointmentError(error))
  }
}

// Function Handle Undo Transition Appointment
function* handleUndoTransitionAppointment(action) {
  try {
    const undo = yield select((state) => state.appointmentReducer.undo)
    // Transition Appointment Request
    yield call(Axios.patch_request, `/appointment/${undo.appointment_uuid}/transition`, {
      "version": "1.0.0",
      "data": { 
        "state": undo.state,
        "location": {
          "uuid": undo.location_uuid
        }
      }
    })
    // Transition Appointment Response
    yield put(transitionAppointmentResponse({...undo, show: false}))
  } catch (error) {
    // Transition Appointment Error
    yield put(transitionAppointmentError(error))
  }
}

// Export
export const TransitionAppointmentSaga = [
  takeLatest(TRANSITION_APPOINTMENT_REQUEST, handleTransitionAppointment),
  takeLatest(UNDO_TRANSITION_APPOINTMENT_REQUEST, handleUndoTransitionAppointment)
]