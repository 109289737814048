import React from 'react';

import { Link } from 'react-router-dom';

export const UserProfile = () => {
  // Function to Get Location UUID
  function getLocationUUID() {
    const afterHash = window.location.href.split("#")[1];
    const params = new URLSearchParams(afterHash.split("?")[1]);
    const location_uuid = params.get("location_uuid");

    return location_uuid;
  }

  return (
    <div className="">
      <div className="relative flex justify-end inline-block group w-41px sm:w-52">
        <div className="my-2 text-center text-white bg-gray-500 rounded-full cursor-pointer h-41px w-41px">
          <i className="inline-block text-2xl fal fa-user pt-4px -ml-1px"></i>
        </div>
        <ul className="absolute right-0 hidden w-56 border border-gray-300 rounded-lg shadow-md top-14 group-hover:block">
          {/* <Link to="/locations">
            <li className="block px-4 pt-3 pb-2 whitespace-no-wrap bg-white rounded-t-lg hover:bg-blue-50">
              Leave Location / Window
            </li>
          </Link> */}
          <Link to={`/windows?location_uuid=${getLocationUUID()}`}>
            <li className="block px-4 pt-4 pb-2 whitespace-no-wrap bg-white rounded-t-lg hover:bg-blue-50">
              Leave Window
            </li>
          </Link>
          {/* <Link to="/settings">
            <li className="block px-4 pt-3 pb-2 whitespace-no-wrap bg-white rounded-t-lg hover:bg-blue-50">
              Settings
            </li>
          </Link> */}
          <Link to={`/report?location_uuid=${getLocationUUID()}`}>
            <li className="block px-4 pt-3 pb-2 whitespace-no-wrap bg-white rounded-t-lg hover:bg-blue-50">
              Report
            </li>
          </Link>
          <Link to="/">
            <li className="block px-4 pt-2 pb-3 whitespace-no-wrap bg-white rounded-b-lg hover:bg-blue-50">
              Sign Out
            </li>
          </Link>
        </ul>
      </div>
    </div>
  );
};

export default UserProfile;
