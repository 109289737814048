// Package Dependencies
import React from 'react'
import ReactDOM from 'react-dom'
import './assets/css/index.css'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { createStore, applyMiddleware, compose } from "redux"
import { Provider } from "react-redux"
import createSagaMiddleware from "redux-saga"

// Additional Dependencies
import RootReducer from "./reducer/index"
import RootSaga from "./saga/index"

// Define Environment
const env = process.env.NODE_ENV

// Define Middleware
const sagaMiddleware = createSagaMiddleware()
const composeMiddleware = () => {
  let middleware = [applyMiddleware(sagaMiddleware)]
  if (env === "development" && window.__REDUX_DEVTOOLS_EXTENSION__) {
    middleware.push(window.__REDUX_DEVTOOLS_EXTENSION__())
  }
  return compose(...middleware)
}

// Create Redux Store
const store = createStore(RootReducer, composeMiddleware())

// Run Saga Middleware
sagaMiddleware.run(RootSaga)

ReactDOM.render(
  <Provider store={store}>
    <React.StrictMode>
      <App />
    </React.StrictMode>
  </Provider>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
