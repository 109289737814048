import { DateTime } from 'luxon';
// Package Dependencies
import {
  call,
  put,
  takeLatest,
} from 'redux-saga/effects';

// Action Dependencies
import {
  RETRIEVE_APPOINTMENTS_REQUEST,
  retrieveAppointmentsError,
  retrieveAppointmentsResponse,
} from '../../action/Appointments/Appointments';
// Additional Dependencies
import Axios from '../../utility/Axios';

// Function to Get Location UUID
function getLocationUUID() {
  const afterHash = window.location.href.split("#")[1];
  const params = new URLSearchParams(afterHash.split("?")[1]);
  const location_uuid = params.get("location_uuid");

  return location_uuid
}

// Function Handle Appointments
function* handleAppointments(action) {
  try {
    // Define Query Parameters
    const transition_state = 'true'
    const location_uuid = getLocationUUID()
    const date = DateTime.now().setZone("UTC").toFormat('yyyy-MM-dd').toLocaleString(DateTime.DATE_SHORT)
    // Retrieve Appointments Request
    const response = yield call(Axios.get_request, `/appointment?transition_state=${transition_state}&date=${date}`)
    // Filter Appointments by Current Location
    const data = response.data.data.filter(item => item.location.uuid === location_uuid || item.location.uuid === action.uuid)
    // Find Active Appointment Index
    const active_index = data.findIndex(item => item.location.uuid !== location_uuid)
    // Find Active Appointment
    const active_appointment = data[active_index] || data[0]
    // Retrieve Appointments Response
    yield put(retrieveAppointmentsResponse(data, active_index, active_appointment))
  } catch (error) {
    // Retrieve Appointments Error
    yield put(retrieveAppointmentsError(error))
  }
}

// Export
export const AppointmentsSaga = [ takeLatest(RETRIEVE_APPOINTMENTS_REQUEST, handleAppointments) ]