// Package Dependencies
import React from "react";
import { connect } from "react-redux";
import { DateTime } from "luxon";
import MainCard from "@alliancesafetycouncil/asc-card";
import Button from "@alliancesafetycouncil/asc-button";

class Invite extends React.Component {

  constructor(props) {
    super(props)
  }

  render() {
    const { active_appointment, handleTransitionAppointment, active_index } = this.props
    const data = active_appointment

    if (data) {
      return (
        <div className="mx-2 mb-1">
          <MainCard type="main">
            <div className="flex flex-col sm:flex-row">
              <div className="sm:flex-grow mr-0 sm:mr-4 mb-4 sm:mb-0">
                <div>
                  <i className="fal fa-clock text-blue-500 mr-2 mb-4 text-lg"></i>
                  <span className="text-blue-500 text-lg">Next Appointment</span>
                </div>
                <div>
                  <span className="text-gray-700">{DateTime.fromISO(data.appointment.date_time).setZone("UTC").toLocaleString(DateTime.TIME_SIMPLE)}</span>
                </div>
                <div>
                  <span className="text-2xl font-medium">{`${data.contact.name.first} ${data.contact.name.last}`}</span>
                </div>
                <div>
                  <span className="text-gray-700">{data.appointment.type.name}</span>
                </div>
              </div>
              <div className="flex items-end">
                <Button type="save" size="md" onClick={() => handleTransitionAppointment(active_index, "assigned")}> Invite to Window</Button>
              </div>
            </div>
          </MainCard>
        </div>
      );
    }

    return null
  }
}

const mapStateToProps = (state) => {
  return {
    data: state.appointmentsReducer.data
  }
}

export default connect(mapStateToProps, null)(Invite)
