// Package Dependencies
// Additional Dependencies
import {
  SET_ACTIVE_APPOINTMENT,
  RETRIEVE_APPOINTMENTS_REQUEST,
  RETRIEVE_APPOINTMENTS_RESPONSE,
  RETRIEVE_APPOINTMENTS_ERROR
} from '../../action/Appointments/Appointments'

// Define Initial State
const INITIAL_STATE = {
  busy: false,
  processing: false,
  response: false,
  error: false,
  data: [],
  active_appointment: {},
  active_index: 0
}

// Appointments Reducer
function AppointmentsReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    // Set Active Appointment
    case SET_ACTIVE_APPOINTMENT:
      return {
        ...state,
        active_index: action.active_index,
        active_appointment: action.active_appointment
      }
    // Retrieve Appointments Request
    case RETRIEVE_APPOINTMENTS_REQUEST:
      return {
        ...state,
        busy: true,
        error: false,
        response: false
      }
    // Retrieve Appointments Response
    case RETRIEVE_APPOINTMENTS_RESPONSE:
      return {
        ...state,
        busy: false,
        error: false,
        response: true,
        data: action.data,
        invite: action.active_index < 0 ? false : true,
        active_index: action.active_index > -1 ? action.active_index : 0,
        active_appointment: action.active_appointment
      }
    // Retrieve Appointments Error
    case RETRIEVE_APPOINTMENTS_ERROR:
      return {
        ...state,
        busy: false,
        error: true,
        response: false,
        message: 'Retrieve Appointments Error'
      }
    default:
      return state
  }
}

// Export
export default AppointmentsReducer