import React from "react";
import { Link } from "react-router-dom"

export const UndoAction = ({ handleUndoTransition }) => {

  return (
    <div className="py-2 px-4 bg-gray-900 items-center rounded-lg flex flex-row mr-4">
      <div className="text-white mr-12">Meeting with User 1 ended.</div>
      <div className="whitespace-nowrap" onClick={handleUndoTransition}><div style={{ color: "#1B91C7", cursor: "pointer" }}><i className="far fa-undo"></i> Undo</div></div>
    </div>
  );
};

export default UndoAction;
