import React from "react";
import { useParams } from 'react-router-dom';
import MainCard from "@alliancesafetycouncil/asc-card";
import Button from "@alliancesafetycouncil/asc-button";
import Logo from "../../assets/img/fast-pass-logo.jpg";
import UserProfile from "../../components/UserProfile/UserProfile";
import { locations } from "../../data/locations-windows";
import PageTitle from "@alliancesafetycouncil/asc-page-title";
import { Link, useHistory } from 'react-router-dom';

export const WindowSettings = () => {

  const history = useHistory();

  const goBack = () => {
    history.goBack(); // Go back to the previous view
  };

  const FSLogo = (e) => {
    return (
      <img src={Logo} alt="" className="w-36" />
    );
  }

  const { id } = useParams();

  // Filter the locations array based on the ID in the URL
  const location = locations.find(location => location.id === parseInt(id));

  if (!location) {
    return <div>Location not found.</div>;
  }



  return (
    <div className="flex flex-col bg-blue-50 h-screen">
      {/* Main Header wrapper */}
      <div className="fixed bg-white w-full border-b border-gray-200">
        <div className="my-4 mx-4 flex flex-column">
          <div className="flex-grow">
            <FSLogo />
          </div>
          <div className="flex items-center">
            <UserProfile />
          </div>
        </div>
      </div>
      {/* Main Card wrapper */}
      <div className="mt-24 m-4 row content flex-auto">
        <div className="mb-3 flex flex-row">
          <div>
            <PageTitle>
              <div className="flex flex-row items-center">
                <i class="fal fa-cog text-2xl text-orange-500 mr-1"></i>
                <div className="text-black">{location.name}</div>
                <Link className="flex flex-row items-center text-base text-blue-500" onClick={goBack} to=''>
                  <div className="ml-5"><i class="fal fa-long-arrow-left"></i></div>
                  <div className="ml-1">Back</div>
                </Link>
              </div>
            </PageTitle>
          </div>
        </div>
        <MainCard type="main">
          {/* Locations Grid */}
          <div className="flex flex-col">
            <div className="flex flex-row justify-end">
            <Button type="primary" size="md">
              Add Window
            </Button>
            </div>
            <div className="flex flex-col">
              {/* Header */}
              <div className="grid grid-cols-4 gap-3 h-12 bg-blue-50 mt-4 items-center px-3 font-medium text-gray-700">
                <div>
                  Window
                </div>
                <div>
                  Occupied
                </div>
                <div>
                  Status
                </div>
                <div></div>
              </div>

              {location.windows.map((window, index) => (
                // tailwind make even rows gray
                <div
                  key={location.id}
                  className={`grid grid-cols-4 gap-3 px-3 h-12 items-center ${index % 2 === 1 ? 'bg-gray-100' : 'bg-white'}`}
                >
                  <div>{window.name}</div>
                  <div>{window.occupied ? "Yes" : "No"}</div>
                  <div>{window.status}</div>
                  <div className="flex justify-end"><Link className="mr-2" to="">Edit</Link> | <Link className="ml-2 text-red-500" to="">Delete</Link></div>
                </div>
              ))}

            </div>
          </div>
        </MainCard>
      </div>
    </div>
  );
};

export default WindowSettings;
